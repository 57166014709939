import styled from "@emotion/styled";
import { ALWAYS_ON_TOP, LAYER_ONE, LAYER_TWO } from "constants/z-index";
const IMAGE_DEFAULT_MAX_WIDTH = "900px";
const DEFAULT_ASSETS_AND_TAGS_EXTRA_SPACE = "10vw";
const getBreathAnimation = (isCentralized)=>{
    const transformStyle = isCentralized ? "translateX(-50%)" : "";
    return `
    @keyframes breathe {
    0% {
      transform: ${transformStyle} scale(1);
    }
    50% {
      transform: ${transformStyle} scale(1.05);
    }
    100% {
      transform: ${transformStyle} scale(1);
    }
  }
  `;
};
export const StyledTagsAndAssetsComponent = styled.div`
	position: relative;
	width: fit-content;

	&.centered {
		.use-cases-container {
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&:not(.centered) {
		.use-cases-container {
			transition: left 1s ease, right 1s ease, transform 1s ease;
			${({ flipUseCasesAndAssets })=>flipUseCasesAndAssets ? "right: 0;" : "left: 0;"}
		}

		.assets-container {
			transition: margin 1s ease;
			${({ flipUseCasesAndAssets, assetsAndTagsExtraSpace })=>flipUseCasesAndAssets ? `margin-right: ${assetsAndTagsExtraSpace || DEFAULT_ASSETS_AND_TAGS_EXTRA_SPACE};` : `margin-left: ${assetsAndTagsExtraSpace || DEFAULT_ASSETS_AND_TAGS_EXTRA_SPACE};`}
		}
	}

	.use-cases-container {
		z-index: ${LAYER_TWO};
		position: absolute;
		top: ${({ useCasesTopMargin })=>useCasesTopMargin || "calc(132 * (100vw / 1920))"};

		&.breathe {
			animation: breathe 0.3s linear;
		}
	}

	img {
		width: 100%;
		object-fit: contain;
		max-width: ${({ imageMaxWidth })=>imageMaxWidth || IMAGE_DEFAULT_MAX_WIDTH};
	}

	.default-asset {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.active {
			display: block;
			z-index: ${ALWAYS_ON_TOP};
			opacity: 1;
		}
	}

	.assets-container {
		z-index: ${LAYER_ONE};
		position: relative;
		width: fit-content;
		min-width: ${({ imageMaxWidth })=>imageMaxWidth};

		.picture-wrapper {
			width: fit-content;
			position: absolute;
			opacity: 0;
			top: 0;
			left: 0;
			transition: opacity 0.3s ease-in-out;

			&.selected {
				opacity: 1;
			}
		}
	}

	${({ isCentralized })=>getBreathAnimation(isCentralized)}
`;
